import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";

import pic01 from "../assets/images/pic01.jpg";
import pic02 from "../assets/images/pic02.jpg";
import pic03 from "../assets/images/pic03.jpg";
import pic04 from "../assets/images/pic04.jpg";

class Homepage extends React.Component {
  render() {
    const siteTitle = "Photon Sites";

    return (
      <Layout>
        <Helmet title={siteTitle} />

        <section id="one" className="main style1">
          <div className="grid-wrapper">
            <div className="col-6">
              <header className="major">
                <h2>
                  Coming Soon
                </h2>
              </header>
            </div>
          </div>
        </section>

      </Layout>
    );
  }
}

export default Homepage;
